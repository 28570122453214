<script setup>
import { ref, defineExpose } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
    default: "Name",
  },
  type: {
    type: String,
    default: "text",
  },
  isChecked: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});

// Reactive value based on prop
const isChecked = ref(props.isChecked);

// Method to update the reactive value
const updateValue = (event) => {
  isChecked.value = event.target.checked;
  console.log(isChecked.value);
};

// Expose getData method
defineExpose({
  getData: () => {
    return { isChecked: isChecked.value };
  },
});
</script>

<template>
  <div class="formComponentBody">
    <div class="checkboxBody" :style="{ width: props.width }">
      <div class="headingAndCheckbox">
        <input
          type="checkbox"
          class="checkbox item"
          :checked="isChecked"
          @change="updateValue"
          required
        />
        <div class="formComponent_Title item">{{ props.label }}</div>
      </div>
      <p class="descriptionText">{{ props.description }}</p>
    </div>
  </div>
</template>

<style scoped>
.item {
  display: inline-block;
  margin: 10px;
}

.checkbox {
  width: auto;
  padding: 0;
  margin: 0;
}

.formComponentBody .descriptionText {
  font-size: 0.75rem;
  font-weight: 400;
  color: #606060;
}

.formComponent_Title {
  padding-left: 10px;
}

.checkboxBody {
  padding: 10px;
  padding-top: 0;
}
</style>
