import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder {
  api_request = new api_request("/api/service");

  async createPage(name, description, contentType, createdBy, createdAt, slug) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "createPage";
      magicJson.data = [
        {
          name: name,
          description: description,
          contentType: contentType,
          createdBy: createdBy,
          createdAt: createdAt,
          modifiedBy: createdBy,
          modifiedAt: createdAt,
          slug,
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createComponent(name, global, description, createdBy, createdAt, slug) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "createComponent";
      magicJson.data = [
        {
          name: name,
          global: global,
          description: description,
          createdBy: createdBy,
          createdAt: createdAt,
          modifiedBy: createdBy,
          modifiedAt: createdAt,
          slug,
          componentData: [],
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateComponent(id, name, description, modifiedBy, modifiedAt, componentData) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updateComponent";
      magicJson.data = [
        {
          id,
          name,
          description,
          modifiedBy,
          modifiedAt,
          componentData,
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updatePage(id, name, description, modifiedBy, modifiedAt, componentData, seoMetaData = {}) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updatePage";
      magicJson.data = [
        {
          id,
          name,
          description,
          modifiedBy,
          modifiedAt,
          componentData,
          seoMetaData
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }


  async getPages(conditions = {}) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getPages";
      magicJson.data = [conditions];
      console.log(magicJson.data)
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getComponents(filter = {}) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getComponents";
      magicJson.metadata.data.filter = filter
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deletePage(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deletePage";
      magicJson.data = [{ id }]
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteComponent(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteComponent";
      magicJson.data = [{ id }]
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }
}

export default ContentTypeBuilder;
