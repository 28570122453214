<script setup>
import GenericContainer from "./GenericContainer.vue";
import DynamicComponent from "./DynamicComponent.vue";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import Button from "@/components/AdminWidgets/Button.vue";
import ContentBox from "@/components/ContentBox/ContentBox.vue";
import { useStore } from "vuex";

const props = defineProps({
  data: Array,
});

// const data2 =
//   [
//     {
//       "type": "checkbox",
//       "label": "whatever",
//     },
//     [
//       "parent",
//       {
//         "type": "text",
//         "label": "Name",
//       },
//       {
//         "type": "checkbox",
//         "label": "Agree"
//       },
//       [
//         "parent2",
//         {
//           "type": "text",
//           "label": "Name",
//         },
//         [
//           "parent99",
//           {
//             "type": "text",
//             "label": "Name",
//           },
//         ]
//       ]
//     ],
//     [
//       "parent3",
//       {
//         "type": "text",
//         "label": "Name",
//       },
//       {
//         "type": "checkbox",
//         "label": "Agree"
//       }
//     ]
//   ]

const showModal = ref(false);

function checkType(value) {
  console.log(value);
  if (value == "Components") {
    return DynamicComponent; // Return the component object
  } else if (value != "Components") {
    return GenericContainer; // Return the component object
  } else {
    return null; // Optionally handle other cases or return null
  }
}

const store = useStore();
const items = computed(() => store.getters.items);

// Reactive states
const isOpen = ref(false);
const isAddFieldModalOpen = ref(false);

// Function to toggle the component
const toggleComponent = () => {
  isOpen.value = !isOpen.value;
};

// function to open add field modal
const handleToggleAddField = () => {
  isAddFieldModalOpen.value = !isAddFieldModalOpen.value;
};

const openComponentModal = async () => {
  showModal.value = !showModal.value;
  // if(!showModal.value) {
  //   // let temp = items.value[0].componentData;
  //   // console.log(items.value[items.value.length - 1])
  //   // await addComponentById(temp, props.data.id, items.value[items.value.length - 1]);
  //   // let temp2 = items.value[0];
  //   // temp2.componentData = temp;
  //   // store.dispatch("updateItem", {index: 0, newItem: temp2})
  //   await submitData();
  // }
};

const submitData = async () => {
  let temp = items.value[0].componentData;
  await addComponentById(
    temp,
    props.data.id,
    items.value[items.value.length - 1]
  );
  let temp2 = items.value[0];
  temp2.componentData = temp;
  store.dispatch("updateItem", { index: 0, newItem: temp2 });
};

async function addToArray(arr, name, newElement) {
  let targetArray = [];
  // Helper function to search for the array with the matching name
  function findArray(arr, name) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        // Check if the first element is the name we are looking for
        if (typeof arr[i][0] === "string" && arr[i][0] === name) {
          return arr[i]; // Return the array if found
        }
        // Recursively search nested arrays
        const nestedResult = findArray(arr[i], name);
        if (nestedResult) {
          return nestedResult;
        }
      }
    }
    return null; // Return null if the array with the name is not found
  }

  // Find the target array using the helper function
  if (arr && arr.length) {
    targetArray = findArray(arr, name);
  }

  if (targetArray) {
    targetArray.push(newElement); // Add the new element (array or object) to the target array
  } else {
    console.error("Array with the given name not found.");
  }
}

async function addComponentById(components, targetId, newComponent) {
  // Recursive helper function to search for the target id
  function findAndAdd(componentsList) {
    for (let component of componentsList) {
      // If the current component matches the target id, add the new component
      if (component.id === targetId) {
        if (!component.childComponents) {
          component.childComponents = []; // Initialize if not present
        }
        component.childComponents.push(newComponent);
        return true; // Return true if the component was added
      }

      // If the component has childComponents, search recursively
      if (component.childComponents && component.childComponents.length > 0) {
        const found = findAndAdd(component.childComponents);
        if (found) return true;
      }
    }
    return false; // Return false if the component with the target id wasn't found
  }

  return findAndAdd(components);
}
</script>

<template>
  <!-- <div class="dynamicParent">
        <h1>{{ data[0] }}</h1>
        <component v-for="(d, index) in data" :key="index" :is="checkType(d)" :data="d"/>
    </div> -->
  <div class="main-frame">
    <div class="container">
      <div class="margin-left-1rem" v-if="loading"><span>Loading...</span></div>
      <div class="item">
        <template v-if="!true">
          <div class="add-container">
            <span class="btn-desc-container">
              <button class="add-btn" @click="handleToggleAddField">
                <span>
                  <v-icon name="hi-plus-sm" fill="#606060"></v-icon
                  ><span>Add Field</span></span
                >
              </button>
              <p>
                The field type defines what content can be stored. For instance,
                a text field accepts titles and descriptions, and a media field
                is used for images and videos.
              </p></span
            >
          </div>
        </template>
        <template v-else>
          <template v-if="!loading">
            <section>
              <div class="body-container">
                <div class="component-container">
                  <span
                    class="toggle"
                    @click="toggleComponent"
                    :class="{ rotated: isOpen }"
                  >
                    <v-icon class="icon" name="bi-caret-right-fill"></v-icon
                  ></span>
                  <div class="generic-parent">
                    <div class="leftComp">
                      <!-- <img src="@/assets/radix-icons_component-13.svg"/> -->
                      <img src="@/assets/radix-icons_component-13.svg" />
                      <span class="contentType"> {{ props.data.label }} </span>
                    </div>
                    <span class="componentParentSpan"> Component </span>
                    <span class="internalizationParentSpan">
                      English(US), English(UK)</span
                    >
                    <span>
                      <v-icon name="la-edit-solid" />
                      <v-icon class="trashIcon" name="fa-trash-alt" />
                    </span>
                  </div>
                </div>
                <div
                  class="subcomponent-container"
                  v-if="isOpen && data.childComponents.length"
                >
                  <component
                    v-for="(d, index) in data.childComponents"
                    :key="index"
                    :is="checkType(d.componentName)"
                    :data="d"
                  />
                </div>
                <div class="addComponentBtn">
                  <button class="addComponentBtn" @click="openComponentModal">
                    +</button
                  ><span>Add another field to this component</span>
                </div>
              </div>
            </section>
          </template>
        </template>
      </div>
    </div>
  </div>
  <ContentBox
    :isOpen="showModal"
    :close="openComponentModal"
    @handleFinishInParent="submitData"
  />
</template>

<style scoped lang="scss">
.container {
  width: 100%;
}

.item {
  .add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    width: 98%;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .btn-desc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;

    p {
      text-align: center;
      font-size: 14px;
    }
  }

  section {
    width: 100%;
    margin: 0;
    color: #606060;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    .body-container {
      margin-top: 8px;

      .subcomponent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    .component-container {
      border: 1px solid blue;
      border-radius: 10px;
      padding: 10px;
      margin: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .generic-parent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2.5rem 1rem 1rem;
      /* margin:2.5rem; */
      /* padding: 0.5rem; */
      padding: 0.5rem 3rem 0.5rem 0rem;
      width: 100%;
    }

    .leftComp {
      display: flex;
      align-items: center;
    }

    .contentType {
      margin-left: 1rem;
    }

    .subcomponent-container {
      border: 1px solid blue;
      margin: 1rem;
      /* padding: 10px; */
      background-color: #f8f8f8;

      .subcomponent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 0.5rem 1rem; */
        margin-bottom: 8px;
      }
    }

    .addComponentBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background-color: transparent;
        border: 2px solid #606060;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 23px;
        font-size: 1.5rem;
        color: #606060;
        cursor: pointer;
        margin-right: 10px;
      }

      span {
        font-size: 1rem;
        color: #606060;
        font-weight: 600;
      }

      button:hover {
        background-color: #f0f0f0;
      }

      span:hover {
        text-decoration: underline;
      }
    }
  }
}

.subcomponent-container .component-container {
  border: 1px solid blue;
  margin: 0rem 1rem 0rem 1rem;
  /* padding: 10px; */
  background-color: #f8f8f8;
}

.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
</style>
