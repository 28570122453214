<script  setup>
import { logos as logo } from "@/assets/radix-icons_component-3.svg";
import { onMounted, ref, computed } from 'vue';
import { useStore } from "vuex";

const store = useStore();
const items = computed(() => store.getters.items);

const props = defineProps({
    data: Object
});

onMounted(() => {
  getIcon(props.data.componentName);
});

// export default {
//   name: 'GenericContainer',
//   components: {
//     logo, // Register the imported SVG component
//   },
// };

const icon = ref("");

const getIcon = async (type) => {
  if(type == "Short Text" || type == "Long Text" || type == "Text")
    icon.value = require("@/assets/radix-icons_component-1.svg");
  else if(type == "Email")
    icon.value = require("@/assets/radix-icons_component-2.svg");
  else if(type == "Rich Text")
    icon.value = require("@/assets/radix-icons_component-3.svg");
  else if(type == "Password")
    icon.value = require("@/assets/radix-icons_component-4.svg");
  else if(type == "Number")
    icon.value = require("@/assets/radix-icons_component-6.svg");
  else if(type == "Enumeration")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if(type == "Date")
    icon.value = require("@/assets/radix-icons_component-7.svg");
  else if(type == "Media")
    icon.value = require("@/assets/radix-icons_component-8.svg");
  else if(type == "Boolean")
    icon.value = require("@/assets/radix-icons_component-9.svg");
  else if(type == "JSON")
    icon.value = require("@/assets/radix-icons_component-10.svg");
  else if(type == "Collection")
    icon.value = require("@/assets/radix-icons_component-11.svg");
}

const deleteComponent = async (id) => {
  let obj = items.value[0].componentData;
  obj = await deleteById(obj, id);
  let page = items.value[0];
  page.componentData = obj;
  store.dispatch("updateItem", { index: 0, newItem: page });
  console.log(obj)
}

async function deleteById(arr, id) {
  // Base case: If the array is empty, return the array as is
  if (!arr || arr.length === 0) return arr;

  // Iterate over the array
  return arr.filter(item => {
    // Check if the current object's id matches the one to be deleted
    if (item.id === id) {
      return false; // Exclude the item from the array
    }

    // If the item has childComponents, call the function recursively
    if (item.childComponents && item.childComponents.length > 0) {
      item.childComponents = deleteById(item.childComponents, id);
    }

    return true; // Keep the current item in the array
  });
}

</script>

<template>
  <div class="genericParent">
      <div class="leftComp">
        <img :src="icon" />
        <span class="contentType"> {{props.data.label}} </span>
      </div>
      <span class="componentNameSpan"> {{props.data.componentName}} </span>
      <span class="internalizationSpan">English(US), English(UK)</span>
      <span> 
        <v-icon name="la-edit-solid"/>
        <v-icon class="trashIcon" @click="deleteComponent(props.data.id)" name="fa-trash-alt"/>
      </span>
  </div>
</template>

<style scoped>
.trashIcon {
  color: red;
}

.genericParent {
  display: flex;
  flex-direction: row;
  border: solid 1px;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 400;
  min-height: 20px;
  align-items: center;
}

.leftComp {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentType {
  margin-left: 1rem;
}

.internalizationSpan {
  margin-right: -2rem;
}
</style>
